// Palette from COLORS page in Moqups
@bswh-dark-blue: #0084B6;
@bswh-medium-blue: #2AA0CD;
@bswh-light-blue: #5887DA;
@bswh-yellow: #FFC843;
@bswh-black: #00000;
@bswh-charcoal: #3E3E3E;
@bswh-slate: #53575A;
@bswh-gray: #B3B3B3;
@bswh-light-gray: #DFDFDF;
@bswh-lighter-gray: #F5F5F5;
@bswh-dark-white: #F7F7F7;

// Theme Colors
@text-gray: #AAAAAA;
@white: #FFFFFF;
@black: #000000;
@dark-gray: #222222;
@line-gray: #7A7A7A;
@market-main: @bswh-dark-blue;
@market-hover: #215583;
@dark-blue: @bswh-dark-blue;
@light-gray: @bswh-light-gray;
@lighter-gray: @bswh-lighter-gray;

// Fonts
@sans-serif-font-stack: 'Source Sans Pro', Arial, Helvetica, sans-serif;
@header-font-stack: 'SharpSans', 'Source Sans Pro', Arial, Helvetica, sans-serif;

.juicer-feed a:hover {
  color: #b2b2b2;
}
.juicer-feed .j-meta a:hover {
  color: #000000;
}
.j-twitter-poster:hover {
  color: #b2b2b2;
}

:root {
  --market-main: @market-main;
  --mobile-nav-level2-bg: #CDCDCD;
  --mobile-nav-level2-fg: white;
  --mobile-nav-level3-bg: white;
  --mobile-nav-level3-fg: black;

  --floating-label-text-input-border-color: @bswh-dark-blue;
  --floating-label-text-input-label-color: @bswh-gray;
  --floating-label-text-input-label-active-color: @bswh-dark-blue;
}

@import '_base.less';
@import '../modules/newsroom/assets/styles/_floatingLabelTextInput';

html body {
  padding-bottom: 0!important;
}

.color--bswh-yellow {
  color: @bswh-yellow;
}

.fa-twitter::before {
  content: "𝕏"!important;
}
.fa-twitter-square::before {
  content: "𝕏"!important;
}

// =====================================================================================================================
// BSWH Mixins
//
// <editor-fold>
// =====================================================================================================================
.triangle-container() {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media(max-width: @screen-xs-max) {
    bottom: -40px;
  }
}

.triangle() {
  &:before {
    border-bottom: 400px solid @bswh-dark-blue;
    border-right: 400px solid transparent;
    left: 0;
    position: absolute;
    height: 400px;
    width: 400px;
    content: "";
    top: 0;
    z-index: -1;
  }
}
// </editor-fold>

// =====================================================================================================================
// Homepage
//
// <editor-fold>
// =====================================================================================================================
.homepage-carousel-outer-container {
  margin-bottom: 10px!important;

  @media(max-width: @screen-sm-max) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px!important;;
  }

  @media(min-width: @screen-md-min) {
    margin-bottom: 30px!important;;
  }
}

.homepage-carousel {
  height: ~"calc(50vw)";
  max-height: 382px;
  overflow: hidden;
  display: grid;
  grid-template-areas: "flickity";
  grid-template-columns: 100%;
  font-family: @header-font-stack;
  @media(max-width: @screen-xs-max) {
    height: ~"calc(60vw)";
    max-height: 442px;
  }

  @media(min-width: @screen-sm-min) {
    grid-template-areas: "flickity item1"
                         "flickity item2"
                         "flickity item3";

    grid-template-columns: 65% 35%;
  }

  .flickity-button {
    display: none;
  }
}

.homepage-carousel-flickity {
  grid-area: flickity;
  .flickity-viewport {
    @media(max-width: @screen-xs-max) {
      overflow: visible!important;
    }
  }
}

.homepage-carousel-cell {
  width: 100%;
  height: ~"calc(50vw)";
  max-height: 382px;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;

  @media(min-width: @screen-sm-min) {
    padding: 20px;
  }
}

.homepage-carousel-cell__teaser-container {
  //padding: 10px;
  background-color: fade(@bswh-medium-blue, 90%);
  color: white;
  font-size: 1.8rem;
  line-height: 2.3rem;
  .triangle-container();

  @media(min-width: @screen-sm-min) {
    color: black;
    background-color: rgba(255,255,255,0.75);
    font-size: 1.8rem;
    margin-bottom: 20px;
    font-style: italic;
  }
}

.homepage-carousel-cell__teaser {
  padding: 10px;

  @media(max-width: @screen-xs-max) {
    .triangle();
  }

  @media(min-width: @screen-sm-min) {
    color: black;
    padding: 20px;
  }
}

.homepage-carousel-cell__button-container {
  display: none;
  z-index: 1;

  @media(min-width: @screen-sm-min) {
    display: block;
  }
}

.homepage-carousel-cell__button {
  color: white;
  background-color: rgba(0,0,0,0.1)!important;
  padding: 1rem 4rem;

  &:focus, &:hover {
    background-color: white!important;
    color: @bswh-dark-blue;
  }
}

.homepage-carousel-item {
  padding: 20px;
  background-color: #F5F5F5;
  border-bottom: solid 1px @bswh-light-gray;
  color: @bswh-dark-blue;
  display: none;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  .triangle-container();

  &:last-child {
    border-bottom: none;
  }

  @media(min-width: @screen-sm-min) {
    display: block;
  }
}

.homepage-carousel-item__title {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  margin-bottom: 5px;

  @media(min-width: @screen-md-min) {
    font-size: 2.15rem;
    line-height: 2.5rem;
  }
}

.homepage-carousel-item--active {
  background-color: @bswh-medium-blue;
  color: white;

  .homepage-carousel-item__title {
    .triangle();
  }
}

.homepage-carousel-item__date {
  opacity: 0.8;
}

.homepage-carousel-item--1 {
  grid-area: item1;
}

.homepage-carousel-item--2 {
  grid-area: item2;
}

.homepage-carousel-item--3 {
  grid-area: item3;
}

.homepage-carousel-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100;
  height: 100;
}

.mobile-carousel-title {
  .triangle-container();
  background-color: #258EC2;
}

.mobile-carousel-title__text {
  .triangle();
  color: white;
  padding: 10px;
  font-size: 1.3rem;
  font-family: SharpSans;
  font-weight: 600;

  @media(min-width: @screen-sm-min) {
    display: none;
  }
}

.homepage-cta-container {
  @media(max-width: @screen-sm-max) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.homepage-cta {
  background-color: var(--market-main);
  display: flex;
  align-items: center;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;

  @media(min-width: @screen-sm-min) {
    padding: 20px;
  }
}

.homepage-cta__title {
  color: white;
  font-family: SharpSans;
  font-weight: 600;
  margin: 0;
  font-size: 1.8rem;
  padding-right: 10px;

  @media(min-width: @screen-sm-min) {
    font-size: 2.8rem;
    padding-right: 20px;
  }

  @media(min-width: @screen-md-min) {
    font-size: 3rem;
  }

}

.homepage-cta__message {
  margin: 0;
  color: @bswh-yellow;
  font-size: 1.4rem;
  padding-right: 10px;

  @media(min-width: @screen-sm-min) {
    font-size: 2rem;
  }

  @media(min-width: @screen-md-min) {
    font-size: 2rem;
    padding-right: 20px;
  }
}

// -----------
// Latest News
// -----------

.homepage-latest-news-and-sidebar-container {
  margin-bottom: 10px;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px!important;
  }
}

.homepage-latest-news {
  margin-bottom: 10px;

  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }

  @media(min-width: @screen-lg-min) {
    margin-bottom: 0;
  }
}

.homepage-latest-news__header {
  padding-left: 10px!important;
  padding-right: 10px!important;

  @media(min-width: @screen-sm-min) {
    padding-left: 0!important;
    padding-right: 0!important;
  }
}

.homepage-latest-news__header {
  display: flex;
  justify-content: space-between;
}

.homepage-latest-news__title {
  margin-top: 0;
}

.homepage-latest-news__release-container {
  padding: 0 10px;

  @media(min-width: @screen-sm-min) {
    padding: 0;
  }
}

.homepage-latest-news.release-list .release-container .release-item {
  border-bottom: solid 1px @bswh-light-gray;
  padding-bottom: 20px!important;
  margin-bottom: 20px;

  @media(min-width: @screen-sm-min) {
    padding: 0;
  }

  .release-information {
    order: 2;
  }
  .release-photo-container {
    order: 1;
  }
}

.homepage-latest-news .tab-content .release-item img.release-photo {
  margin: 0;
  padding: 0;
}

.homepage-latest-news.release-list .release-container .release-item a {
  width: unset;
  flex: 40%;

  @media(min-width: @screen-sm-min) {
    flex: 30%;
  }
}

.homepage-latest-news.release-list .release-container .release-item a.release-photo-container {
  padding-right: 10px;
}

.homepage-latest-news.release-list .release-container .release-item div.release-information {
  width: unset;
  padding: 0;
  margin: 0;
  flex: 60%;

  @media(min-width: @screen-sm-min) {
    flex: 70%;
  }
}

.homepage-latest-news.release-list .release-container .release-item .content-title {
  @media(min-width: @screen-sm-min) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.homepage-latest-news.release-list .release-container .release-item .content-title a {
  font-size: 1.3rem;
  @media(min-width: @screen-sm-min) {
    font-size: 2rem;
    font-weight: 600;
    font-family: @header-font-stack;
  }
}

ul.assigned-channels {
  display: none;

  li {
    background-color: #F5F5F5;
    color: @line-gray;
    padding: 2px 6px;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-right: 10px;
    white-space: nowrap;

    a {
      color: unset;
      &:hover {
        color: @bswh-dark-blue;
      }
    }
  }

  @media(min-width: @screen-sm-min) {
    display: flex;
    list-style-type: none;
    margin: 4px 0 0 0;
    padding: 0;
    flex-wrap: wrap;
  }
}

.homepage-latest-news.release-list .release-container .release-item div.release-information .pub-date {
  display: none;
  text-transform: none;
  @media(min-width: @screen-sm-min) {
    display: block;
  }
}

.homepage-latest-news .tab-content .release-item .release-information .grid-item__actions {
  display: none;
}

.homepage-latest-news__footer {
  text-align: center;
  padding: 0 10px;
}

.homepage-latest-news-footer-button {
  width: 100%;
  @media(min-width: @screen-sm-min) {
    width: auto;
  }
}

// -------------
// Regional News
// -------------

.homepage-regional-news {
  padding: 0 10px;
  @media(min-width: @screen-sm-min) {
    padding: 0;
  }
}

.homepage-regional-news__header {
  background-color: @bswh-dark-blue;
  color: white;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(min-width: @screen-sm-min) {
    padding: 15px 20px;
  }
}

.homepage-regional-news__header__buttons {
  display: flex;
}

.homepage-regional-news__header__button {
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }

  .caret {
    margin-left: 6px;
  }
}

.homepage-regional-news__title {
  color: white;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.homepage-regional-news-item-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  border-left: solid 1px #D7D7D7;
  border-right: solid 1px #D7D7D7;

  @media(min-width: @screen-sm-min) {
    border-left: solid 1px #D7D7D7;
    border-right: solid 1px #D7D7D7;
    padding-top: 20px;

    flex-direction: row;
  }

  @media(min-width: @screen-lg-min) {
    flex-direction: column;
  }
}

.homepage-regional-news-item {
  // Stacked
  flex: 1;
  display: grid;
  padding: 0 10px 10px 10px;
  grid-template-areas: "preview title";
  grid-template-columns: 30% 70%;

  @media(min-width: @screen-sm-min) {
    grid-template-areas: "all";
    grid-template-columns: 100%;

    // 2 Items, side-by-side
    &:nth-child(odd) {
      padding: 0 10px 10px 20px;
    }

    &:nth-child(even) {
      padding: 0 20px 10px 10px;
    }
  }

  @media(min-width: @screen-lg-min) {
    // Stacked
    padding: 0 20px 20px 20px!important;
  }
}

.homepage-regional-news-item__link {
  grid-area: title;
  padding: 10px;
  background-color: @bswh-medium-blue;
  color: white;

  @media(min-width: @screen-sm-min) {
    grid-area: all;
    background-color: unset;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    color: white;
    font-family: @header-font-stack;
    font-weight: 700;
    font-size: 1.5rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}

.homepage-regional-news-item__image {
  grid-area: preview;
  //width: 30vw;

  @media(min-width: @screen-sm-min) {
    grid-area: all;
    width: auto;
  }
}

.homepage-regional-news-footer {
  border-bottom: solid 1px #D7D7D7;
  text-align: center;
  //padding-bottom: 20px;
  padding: 0 10px 10px 10px;
  margin-bottom: 10px;

  border-left: solid 1px #D7D7D7;
  border-right: solid 1px #D7D7D7;

  @media(min-width: @screen-xs-min) {
    border-left: solid 1px #D7D7D7;
    border-right: solid 1px #D7D7D7;
  }

  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
  }
}

.homepage-regional-news-footer-button {
  display: none;
  @media(min-width: @screen-lg-min) {
    display: inline-block;
    margin-bottom: 10px;
  }
}

// -----------
// Sidebar CTA
// -----------
.homepage-sidebar-cta {
  display: flex;
  flex-direction: column;

  @media(min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    flex-direction: row;
  }
}

// -------------
// Subscribe Now
// -------------
.homepage-subscribe {
  background-color: @bswh-dark-blue;
  padding: 15px 10px;
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;

  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media(min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    width: ~"calc(50% - 10px)";
    margin-bottom: 0;
  }

  @media(min-width: @screen-sm-min) {
    padding: 30px 25px;
    //margin-bottom: 20px;
  }
}

.homepage-subscribe__title {
  margin: 0;
  color: white;
  font-size: 3rem!important;
}

.homepage-subscribe__message {
  flex: 1;
}

.homepage-subscribe-email-address {
  border-bottom: solid 1px @bswh-yellow;
  display: flex;
}

.homepage-subscribe-email-address__input {
  background: transparent;
  border: none;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
  &::placeholder {
    font-style: italic;
  }
}

.homepage-subscribe-email-address__submit {
  background: transparent;
  color: @bswh-yellow;
  text-transform: uppercase;
  font-weight: 100;
  padding-right: 0;
}

// -------------
// Sidebar Links
// -------------
.homepage-sidebar-link-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media(min-width: @screen-xs-min) and (max-width: @screen-md-max) {
    margin-left: 20px;
  }
}

.homepage-sidebar-link {
  margin-bottom: 10px;
  background-color: @bswh-slate;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px;
    padding: 30px;
  }
}

.homepage-sidebar-link__title {
  margin: 0 0 5px 0;
  font-size: 3rem;
  font-weight: 600;
  color: @bswh-yellow;
}

.homepage-sidebar-link__subtitle {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

// ------------
// Scrubbing In
// ------------
@homepage-scrubbing-in-breakpoint: @screen-sm-min;

.homepage-scrubbing-in-outer-container {
  background-color: #F5F5F5;
  margin-bottom: 10px;
  padding: 10px;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    padding: 30px 10px;
    margin-bottom: 15px;
  }
}

.homepage-scrubbing-in-container {
  margin-bottom: 0!important;
}

.homepage-scrubbing-in-header {
  display: flex;
  justify-content: space-between;
}

.homepage-scrubbing-in-header-visit-button {
  display: none;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    display: block;
  }
}

.homepage-scrubbing-in-item-container {
  margin-top: 10px;
  padding: 0;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    display: flex;
    margin-top: 35px;
  }
}

.homepage-scrubbing-in-item {
  flex: 1;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 36% 64%;
  grid-template-areas: "image link";
  grid-gap: 10px;
  border-bottom: solid 1px @bswh-light-gray;
  padding-bottom: 10px;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    display: grid;
    grid-template-areas: "all";
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    padding: 0 5px;
    border-bottom: none;

    &:first-child {
      padding: 0 5px 0 0;
    }

    &:last-child {
      padding: 0 0 0 5px;
    }
  }

  @media(min-width: @screen-lg-min) {
    padding: 0 15px;
    &:first-child {
      padding: 0 15px 0 0;
    }

    &:last-child {
      padding: 0 0 0 15px;
    }
  }
}

.homepage-scrubbing-in-item__link {
  grid-area: link;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    grid-area: all;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    color: white;
    font-family: @header-font-stack;
    font-weight: 700;
    font-size: 1.5rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

}

.homepage-scrubbing-in-item__image {
  grid-area: image;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    grid-area: all
  }
}

.homepage-scrubbing-in-footer-button {
  width: 100%;
}



// ----------------
// Social Spotlight
// ----------------

.homepage-social-spotlight-container {
  padding-left: 10px!important;
  padding-right: 10px!important;

  margin-bottom: 10px!important;
  @media(min-width: @screen-sm-min) {
    margin-bottom: 20px!important;
    padding-top: 15px;
    padding-bottom: 20px;
  }
}

.homepage-social-spotlight-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.homepage-social-spotlight-title {
  margin-top: 0;
}

.homepage-social-spotlight-social-link {
  font-size: 2.4rem;
  color: @bswh-dark-blue;
  margin-left: 5px;
}

.homepage-social-spotlight-demo-posts {
  display: flex;
  flex-direction: column;

  @media(min-width: @screen-sm-min) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.homepage-social-spotlight-demo-post {
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @media(min-width: @screen-sm-min) {
    margin-bottom: 0;
    margin-right: 5px;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}



// ------------
// In the News
// ------------

.homepage-in-the-news-outer-container {
  padding: 0 10px 10px 10px;
  @media(min-width: @screen-sm-min) {
    background-color: #F5F5F5;
    padding: 20px 0 0 0;
  }

  @media(min-width: @screen-md-min) {
    background-color: #F5F5F5;
    padding: 40px 0;
  }
}

.homepage-in-the-news-container {
  margin-bottom: 0!important;
}

.homepage-in-the-news-header {
  display: flex;
  justify-content: space-between;
}

.homepage-in-the-news-header-visit-button {
  display: none;

  @media(min-width: @homepage-scrubbing-in-breakpoint) {
    display: block;
  }
}

.homepage-in-the-news-title {
  margin-top: 0;
}

.homepage-in-the-news-item-container {
  display: flex;
  padding: 0;
  flex-wrap: wrap;

  @media(min-width: @screen-lg-min) {
    margin-top: 15px;
  }
}

.homepage-in-the-news-item {
  width: 100%;
  display: flex;
  grid-template-areas: "all";
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px @bswh-light-gray;

  @media(min-width: @screen-sm-min) {
    width: 50%;
    padding-bottom: 20px;
    margin-bottom: 25px;

    // Last 2 elements
    &:nth-last-of-type(-n+2) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  @media(min-width: @screen-lg-min) {
    width: 33%;
    padding: 0 10px;
    margin-bottom: 25px;

    // Every 3rd element
    &:nth-child(3n + 0) { // 0, 3, 6, 9, 12...
      padding-right: 0;
    }

    // 1st and evey 4th element
    &:nth-child(3n+1) { // 1, 4, 7, 10, 13, 16...
      padding: 0 10px 15px 0;
    }

    // Last 3 elements
    &:nth-last-of-type(-n+3) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

.homepage-in-the-news-item__image {
  width: 75px;
  margin-right: 10px;
}

.homepage-in-the-news-item__publication_name {
  font-size: 1.8rem;
  font-weight: 600;
}

.homepage-in-the-news-item__headline {
  font-size: 1.5rem;
}

.homepage-in-the-news-item__date {
  font-weight: 100;
  color: @bswh-gray;
}

.homepage-in-the-news-footer {
  padding-bottom: 10px;
  border-bottom: solid 1px @bswh-light-gray;
}

.homepage-in-the-news-footer-button {
  width: 100%;
}

.site--bswh {
  #daterange-desktop__dropdown {
    top: 30px;
  }

  #content-type-tab-bar .custom-media-actions {
    display: none;
    @media(min-width: @screen-sm-min) {
      display: block;
    }
  }

  #content-type-tab-bar .custom-media-actions .custom-btn-group.open ul#sort-options {
    top: 30px;
  }

  .tab-content .release-item {
    border-bottom: solid 1px @bswh-light-gray;
  }

  .homepage .release-item--with-thumbnail .release-information {
    float: none;
  }

  .homepage-latest-news.release-list .release-container .release-item div.release-information {
    width: unset;
  }

  .form-panel {
    margin-top: 0;

    @media(min-width: @screen-sm-min) {
      margin-top: 90px;
      margin-bottom: 50px;
    }

    .panel-default {
      background-color: white;
      box-shadow: none;

      .panel-body {
        padding: 20px 0 0 0;

        .site-page-header {
          text-align: left;
        }

        .site-page-header h2 {
          text-transform: none;
          text-align: left;
          display: flex;
          align-items: center;

          margin-bottom: 25px;
          font-size: 2.4rem;

          @media(min-width: @screen-sm-min) {
            font-size: 2.9rem;
          }

          i.far {
            margin-left: 1rem;
            font-size: 2.1rem;

            @media(min-width: @screen-sm-min) {
              font-size: 2.4rem;
            }
          }
        }
      }

      .form-actions-container {
        margin-top: 10px;
        margin-bottom: 15px;

        .checkbox {
          margin: 0;
        }
      }
    }
  }

  .modal-dialog .modal-content {
    background-color: white;
  }

  #media-modal .modal-dialog .modal-content {
    background-color: #3D3D3D;
  }

  .modal-dialog-preview .photo-modal-image {
    max-height: 67vh;
  }

  .tab-pane {

    .release-item.channel-item .content-title a::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f0e8";
      color: #FFC843;
      padding-right: 10px;
      font-weight: 900;
      padding-left: 1px;
      font-size: 2rem;
    }

    .release-item.page-item .content-title a::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f15c";
      color: #FFC843;
      padding-right: 10px;
      font-weight: 200;
      padding-left: 1px;
      font-size: 2rem;
    }

  }

  .channel-item--channel-path {
    margin: 0;
    padding:0;
  }

  .channel-item--channel-path-item {
    margin: 0;
    padding: 0 3px 0 0;
    display: inline-block;

    &:after {
      font-family: "Font Awesome 5 Pro";
      content: "\f054";
      color: @bswh-gray;
      padding-right: 3px;
      font-weight: 100;
      padding-left: 10px;
      font-size: 1.2rem;
    }

    a {
      color: @bswh-medium-blue;
      text-decoration: underline;
    }

    &:first-child {
      &:before {
        font-family: "Font Awesome 5 Pro";
        content: "\f0c9";
        color: @bswh-gray;
        padding-right: 10px;
        font-weight: 100;
        font-size: 1.2rem;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  .grid-item__actions {
    display: flex;
    justify-items: flex-start;
    align-items: center;
  }

  .grid-item__actions-label {
    float: left;
    color: @line-gray;
    font-size: 1.4rem;
    padding-left: 10px;
    padding-right: 5px;
  }

  .grid-item__actions--list-item {
    margin-top: 10px;
  }
}
// </editor-fold>


// =====================================================================================================================
// Release Media Contacts
//
// <editor-fold>
// =====================================================================================================================
#release-main {
  a {
    color: #337ab7;

    &:hover {
      text-decoration: underline;
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// Release Media Contacts
//
// <editor-fold>
// =====================================================================================================================

.release-contacts {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  line-height: 1.8rem;

  @media(min-width: @screen-sm-min) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.release-contacts-item {
  display: flex;
  background-color: @bswh-dark-white;

  padding: 10px;
  margin-bottom: 10px;
  width: 100%;

  @media(min-width: @screen-sm-min) {
    padding: 10px;
    margin-bottom: 10px;
    width: ~"calc(50% - 5px)";

    &:nth-child(odd) {
      margin-right: 5px;
    }

    &:nth-child(even) {
      margin-left: 5px;
    }
  }

  @media(min-width: @screen-md-min) {
    padding: 20px;
    margin-bottom: 20px;
    width: ~"calc(50% - 10px)";
    &:nth-child(odd) {
      margin-right: 10px;
    }

    &:nth-child(even) {
      margin-left: 10px;
    }
  }
}

.release-contacts-item__panel-title {
  color: @bswh-dark-blue;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 5px;
}

.release-contacts-item__name {
  font-weight: 600;
  color: @bswh-slate;
}

.release-contacts-item__headshot {
  width: 80px;
  height: 80px;
  margin-right: 10px;

  @media(min-width: @screen-sm-min) {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }
}

// </editor-fold>



// =====================================================================================================================
// BSWH Site Footer
//
// <editor-fold>
// =====================================================================================================================

.site-footer-outer-container {
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.with-site-footer-border .site-footer {
  border-top: solid 1px @bswh-light-gray;
}

.site-footer {

  padding-top: 10px;

  @media(min-width: @screen-sm-min) {
    padding-top: 20px;
  }
  @media(min-width: @screen-md-min) {
    padding-top: 110px;
  }

  display: grid;
  grid-template-areas: "copyright"
                       "links"
                       "social"
                       "address-and-phone";

  @media(min-width: @screen-md-min) {
    grid-template-areas: "copyright social"
                         "links address-and-phone";
  }
}

.site-footer__copyright {
  grid-area: copyright;
  text-align: center;
  @media(min-width: @screen-md-min) {
    text-align: left;
  }
}

.site-footer__links {
  grid-area: links;
  text-align: center;
  padding: 10px 0;

  @media(min-width: @screen-md-min) {
    text-align: left;
    padding: 0;
  }
}

.site-footer__link {
  display: inline-block;
  padding: 0 10px 10px 10px;

  @media(min-width: @screen-md-min) {
    padding: 0 10px 0 0;
  }
}

.site-footer__social {
  grid-area: social;
  text-align: center;
  padding: 0 0 10px 0;

  @media(min-width: @screen-md-min) {
    text-align: right;
    padding: 0 0 5px 0;
  }
}

.site-footer__address-and-phone {
  grid-area: address-and-phone;
  text-align: center;
  padding-top: 10px;

  @media(min-width: @screen-md-min) {
    text-align: right;
    padding-top: 0;
  }
}

.site-footer__address {
  display: block;
  padding-bottom: 10px;
  @media(min-width: @screen-sm-min) {
    display: inline;
  }
}

.site-footer__phone {
  display: block;
  @media(min-width: @screen-sm-min) {
    display: inline;
  }
}
// </editor-fold>

// =====================================================================================================================
// BSWH Headers
//
// <editor-fold>
// =====================================================================================================================
.h1--underline {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 2.4rem;

  @media(min-width: @screen-md-min) {
    font-size: 3rem;
  }
}
.h1--underline::after {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 5px;
  background-color: @bswh-yellow;
  margin-left: 0;
  margin-top: 5px;

  @media(min-width: @screen-md-min) {
    width: 70px;
  }
}
// </editor-fold>


// =====================================================================================================================
// BSWH Headers
//
// <editor-fold>
// =====================================================================================================================
.error-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  padding: 15px;

  @media(min-width: @screen-sm-min) {
    padding-top: 100px;
    justify-content: top;
    align-items: center;
  }

  @media(min-width: @screen-md-min) {
    padding-top: 0;
    justify-content: center;
    align-items: center;
  }
}

.error-info-panel {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas: "title icon"
                       "message message";

  @media(min-width: @screen-sm-min) {
    grid-template-columns: auto auto;
    text-align: center;
    grid-template-areas: "icon"
                         "title"
                         "message";
  }
}

.error-info-panel__icon {
  color: #FFC843;
  font-size: 2.4rem;
  grid-area: icon;
}

.error-info-panel__title {
  grid-area: title;
  font-weight: 700;
  font-size: 2rem;
  margin-top: 3px;
  white-space: nowrap;
  padding-right: 5px;

  @media(min-width: @screen-sm-min) {
    font-size: 2.4rem;
    margin-top: 20px;
  }
}

.error-info-panel__message {
  grid-area: message;
}
// </editor-fold>


// =====================================================================================================================
// BSWH Buttons
//
// <editor-fold>
// =====================================================================================================================
.btn--thin {
  border: solid 1px white;
  border-radius: 1px;
  text-transform: uppercase;
  background: transparent;
}

.btn--white {
  color: white;

  &:focus, &:hover {
    color: @bswh-dark-blue;
    background-color: white;
  }
}

.btn--blue {
  border: solid 1px @bswh-dark-blue;
  color: @bswh-dark-blue;

  &:focus, &:hover {
    color: @white;
    background-color: @bswh-dark-blue;
  }
}

.btn--podcast {
  border: solid 1px @bswh-dark-blue;
  background: @bswh-dark-blue;
  color: @white;
  margin-right: 5px;
  @media(max-width: @screen-xs-max) {
    font-size: 12px;
    padding: 5px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:focus, &:hover {
    background: transparent;
    color: @bswh-dark-blue;
  }
}

.btn--extra-padding-w {
  padding-left: 4%;
  padding-right: 4%;
}

.btn--12p-padding-w {
  padding-left: 12%;
  padding-right: 12%;
}

.btn--0-padding-h {
  padding-top: 0;
  padding-bottom: 0;
}

.btn--3-padding-h {
  padding-top: 3px;
  padding-bottom: 3px;
}

.btn--no-text-transform {
  text-transform: none!important;
}
// </editor-fold>

// =====================================================================================================================
// BSWH Modal Dialog
//
// <editor-fold>
// =====================================================================================================================
.modal-social-actions {
  margin-bottom: 10px;
  a {
    color: #b3b3b3;
    font-size: 20px;
    margin-right: 5px;
    transition: ease-in-out .25s;
    &:focus, &:hover {
      color: var(--market-main);
    }
  }
}
// </editor-fold>