@import "_bootstrap_variables";
@import "../node_modules/bootstrap/less/mixins";
@import '_svgicons';
@import '_util';

:root {
  /* Channel, Release, Basket, Search Results Banner min-height */
  --banner-min-height: 200px;
  @media(max-width: @screen-md-max) {
    --banner-min-height: 134px;
  }
}

// =====================================================================================================================
// Global Styles
//
// <editor-fold>
// =====================================================================================================================

// hide recaptcha badge, Google business lingo therefore required
.grecaptcha-badge {
  visibility: hidden;
}

html {
  color: red;
  position: relative;
  min-height: 100%;
  body {
    color: @black;
    font-family: @sans-serif-font-stack;

    // Account for fixed footer
    padding-bottom: 130px;

    @media(min-width: @screen-sm-min) {
      padding-bottom: 80px;
    }
  }
  h1, h2 {
    font-family: @header-font-stack;
    color: @dark-blue;
    a {
      color: @dark-blue;
    }
  }
  h3, h4, h5, h6 {
    font-family: @header-font-stack;
    color: @black;
    a {
      color: @black;
    }
  }
}
*:focus {
  outline-style: none;
}
a, button, .site-navbar-nav a {
  transition: background ease-in-out .25s;
  &:focus {
    outline-style: none;
  }
}
a {
  color: @black;
  &:hover, &:focus {
    color: var(--market-main);
  }
}

h1 {
  color: @black;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

.site-page-header h2 {
  text-transform: uppercase;
}

textarea:focus, input:focus{
  outline-style: none;
}

input.form-control {
  border-radius: 0;
  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

a:focus, a:hover {
  text-decoration: none;
}

.btn-default, .btn-group .btn-default {
  border-radius: 0;
}

select {
  float: left;
  width: auto!important;
  border-radius: 0px!important;
  margin-right: 6px!important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px!important;
  background-color: white!important;
  line-height: 12px!important;
}

div#flash-info {
  margin-top: 40px;
  margin-bottom: 40px;
}

.alert-error {
  background-color: #FFF;
  border: 1px solid var(--market-main);
  border-radius: 0px;
  box-shadow: 0px 0px 15px rgba(178,0,0,0.2);
  color: var(--market-main);
  margin-bottom: 20px;
  padding: 15px;
  .close {
    right: 0;
  }
}

.alert-success {
  border: 1px solid #00B200;
  border-radius: 0px;
  box-shadow: 0px 0px 15px rgba(0,178,0,0.2);
  color: #00B200;
  margin-bottom: 20px;
  padding: 15px;
  .close {
    right: 0;
  }
}

.has-error .control-label {
  color: var(--market-main);
}

.has-error .form-control {
  border-color: var(--market-main);
}

#globalHeader.navbar {
  margin-bottom: 0;
}

.embargoed {
  color: var(--market-main);
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .container.container--tablet {
    .col-xs-12.col-xs-12--tablet {
      padding: 0;
    }
  }
}

.close {
  opacity: .5;
  &:hover, &:focus {
    color: var(--market-main);
    opacity: 1;
  }
}

.tab-pane.channel-tab {
  @media(min-width: @screen-sm-min) {
    height: 100%;
    overflow: hidden;
  }
  margin-top: 30px;
  margin-bottom: 30px;
  .back-to-top-row {
    display: none;
    flex: 1;
    margin: 0;
    @media(min-width: @screen-sm-min) {
      margin: 0 -15px;
    }
    &:last-child {
      display: block;
    }
    .content-back-to-top-footer {
      width: 100%;
    }
  }
  .back-to-top-container {
    margin-top: 10px;
    @media(min-width: @screen-sm-min) {
      margin-top: 20px;
    }
    .back-to-top {
      margin-bottom: 15px;
      @media(min-width: @screen-sm-min) {
        margin-bottom: 0;
      }
    }
  }
  .photos-actions {
    float: left;
    padding: 8px 0;
    .photos-action {
      text-transform: uppercase;
      float: left;
      margin-right: 15px;
      i { color: var(--market-main); }
    }
  }
}

.saved-basket-item {
  color: var(--market-main)!important;
}
// </editor-fold>


// =====================================================================================================================
// Sticky Navbar
//
// <editor-fold>
// =====================================================================================================================
body.site--bswh {
  padding-top: 60px;
  @media(min-width: @screen-sm-min) {
    padding-top: 110px;
  }
}

.navbar.site-navbar.sticky-navbar.hide-nav {
  transform: translateY(-120px);
}

.navbar.site-navbar.sticky-navbar {
  position: fixed;
  top: 0;
  transition: transform 250ms;
  width: 100%;
  z-index: 9998;
}
// </editor-fold>

// =====================================================================================================================
// Header Navigation / Navbar
//
// <editor-fold>
// =====================================================================================================================
.navbar.site-navbar {
  background-color: @white;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border: none;
  height: 60px;
  @media(min-width: @screen-sm-min) {
    height: 110px;
  }

  .navbar-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 60px;
    @media(min-width: @screen-sm-min) {
      height: 110px;
    }

    @media(max-width: @screen-xs-max) {
      margin: 0;
    }
    .nav-header {
      align-items: center;
      display: flex;
      .nav-logo {
        margin-right: 10px;
        img {
          @media(max-width: @screen-xs-max) {
            max-height: 33px;
          }
        }
        @media(min-width: @screen-sm-min) {
          margin-right: 20px;
          img {
            height: 40px;
          }
        }
        @media(min-width: @screen-md-min) {
          margin-right: 30px;
          img {
            height: 50px;
          }
        }
      }
      h3 {
        color: var(--market-main);
        font-size: 18px;
        font-weight: 700;
        margin-top: 15px;
        text-transform: uppercase;
      }
      .hamburger {
        background: transparent;
        border: none;
        display: block;
        float: none;
        margin: 10px 5px 10px 0;
        padding: 10px 5px 10px 0;
        z-index: 4;
        @media(min-width: @screen-sm-min) {
          margin: 10px 10px 10px 0;
          padding: 10px 10px 10px 0;
        }
        @media(min-width: @screen-md-min) {
          margin: 10px 10px 10px 0;
          padding: 10px 20px 10px 0;
        }
        .icon-bar {
          background-color: var(--market-main);
          height: 3px;
          width: 24px;
        }
      }
    }
    .navbar-actions {
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      margin: 0!important;
      a {

        display: flex;
        align-items: center;
        color: var(--market-main);
        font-size: 14px;
        padding: 5px;
        &:hover, &:focus {
          color: var(--market-main);
        }
        @media(min-width: @screen-sm-min) {
          padding: 10px;
        }
        i {
          margin-left: 5px;
          font-size: 18px;
        }
      }
      li:first-child {
        a {
          padding-left: 0;
        }
      }
      li:last-child {
        a {
          padding-right: 0;
        }
      }
      .account-dropdown-menu-btn {
        background: transparent;
      }
      .account-dropdown-menu {
        border: none;
        border-radius: 0;
        left: auto;
        right: 0px;
        top: 0px;
        text-align: right;
        li {
          a.close-account-dropdown-menu {
            color: @text-gray;
          }
          a {
            background: transparent;
            font-size: 18px;
            padding: 5px 20px 5px 5px!important;
            &:hover {
              color: var(--market-main);
            }
            i {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.clientBasketItemCount {
  font-size: 16px;
  position: relative;
  top: -1px;
}

.clientBasketItemCount--full {
  padding-left: 4px;
}
// </editor-fold>


// =====================================================================================================================
// Sidebar Flyout Nav
//
// <editor-fold>
// =====================================================================================================================
#side-navbar {
  background-color: @white;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  display: none;
  padding: 15px 15px 0;
  position: absolute;
  top: 60px;
  transition: ease-in-out .25s;
  width: 100%;
  z-index: 9999;
  @media(min-width: @screen-sm-min) {
    padding: 20px 20px 0;
    top: 100px;
  }
  @media(min-width: @screen-md-min) {
    padding: 30px 30px 40px 30px;
  }

  ul {
    list-style: none;
  }
  ul.custom-navbar-menu {
    column-count: 1;
    column-gap: 8px;
    margin: 0;
    padding-left: 0;
    @media(min-width: @screen-sm-min) {
      column-count: 3;
    }
    @media(min-width: @screen-md-min) {
      column-count: 5;
      padding-bottom: 5px;
    }
    .menu-item__header {
      color: @black;
      font-size: 16px;
      font-weight: 400;
      margin: 0 0 20px;
      padding: 0;
      @media(min-width: @screen-sm-min) {
        font-size: 20px;
      }
      @media(min-width: @screen-md-min) {
        font-size: 2.2rem;
      }
    }
    li.menu-item {
      break-inside: avoid;
      page-break-inside: avoid;
      page-break-before: avoid;
      padding: 0 0 15px;
      @media(min-width: @screen-sm-min) {
        padding: 0 0 20px;
      }
      @media(min-width: @screen-md-min) {
        padding: 0 0 20px;
      }
    }
    ul.submenu {
      break-inside: avoid;
      padding: 0 0 0 15px;
      @media(min-width: @screen-sm-min) {
        padding: 0;
      }
      li.submenu-item {
        margin-bottom: 15px;
        &:last-child {
          margin: 0;
        }
        a {
          font-weight: 600;
        }
      }
    }
  }
  div.menu-divider {
    border-bottom: 1px solid @text-gray;
    padding-top: 15px;
  }
}

#side-navbar.active {
  display: block;
}

@-webkit-keyframes ACTIVATE-NAVBAR {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes ACTIVATE-NAVBAR {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes ACTIVATE-NAVBAR {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes ACTIVATE-NAVBAR {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

#side-navbar.active {
  -webkit-animation: ACTIVATE-NAVBAR .2s ease-in-out; /* Safari 4+ */
  -moz-animation:    ACTIVATE-NAVBAR .2s ease-in-out; /* Fx 5+ */
  -o-animation:      ACTIVATE-NAVBAR .2s ease-in-out; /* Opera 12+ */
  animation:         ACTIVATE-NAVBAR .2s ease-in-out; /* IE 10+, Fx 29+ */
}
// </editor-fold>


// =====================================================================================================================
// Mobile and Tablet Navigation
//
// <editor-fold>
// =====================================================================================================================
#side-navbar {
  ul.custom-navbar-menu.custom-navbar-menu--mobile {
    li.menu-item {
      .menu-item__header {
        background: transparent;
        display: flex;
        font-weight: 100;
        justify-content: space-between;
        margin: 0;
        i::before {
          content: '\f107';
          font-family: "Font Awesome 5 Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
        }
      }
      ul.submenu {
        margin-top: 15px;
        li.submenu-item {
          a {
            color: @black;
            padding: 0;
          }
        }
      }
    }

    li.menu-item.open {
      .menu-item__header {
        i::before {
          content: '\f106';
          font-family: "Font Awesome 5 Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
        }
      }
    }

    .navbar-actions--mobile {
      border-top: 1px solid @bswh-light-gray;
      padding: 15px 0 0;
      li {
        padding-bottom: 15px;
        a {
          color: var(--market-main);
          i {
            font-size: 16px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Search Modal Dialog
//
// <editor-fold>
// =====================================================================================================================
.modal-dialog.search-modal-dialog {
  .modal-content {
    border: none;
    box-shadow: none;
    .modal-header {
      padding-top: 25px;
    }
    .modal-title {
      color: var(--market-main);
      display: flex;
      justify-content: center;
      text-transform: uppercase;
    }
    .table {
      tbody>tr>td {
        border: none;
        padding: 5px 0;
        width: 92px;
      }
      div.form-check {
        input {
          font-size: 20px;
        }
        .form-check-label {
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
    .modal-body {
      padding: 10px 30px 30px 30px !important;
    }
    form.searchbox {
      width: 100%;
      .searchbox-input {
        padding: 0px 15px;
      }
    }
    .modal-footer {
      border-top: 1px solid @line-gray;
      margin: 0 25px;
      padding: 25px 0;
      text-align: initial;
      .go-btn {
        background: transparent;
        border: none;
        display: flex;
        font-size: 20px;
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;
        i {
          color: var(--market-main);
          margin-top: 4px;
          padding-left: 5px;
        }
      }
    }
  }
}

.modal-backdrop.in {
  opacity: .65;
}
// </editor-fold>


// =====================================================================================================================
// Footer Things
//
// <editor-fold>
// =====================================================================================================================
.back-to-top, .load-more-btn {
  color: @black;
  transition: ease-in-out .25s;
  text-transform: uppercase;
  &:hover, &:focus {
    color: var(--market-main);
  }
  i {
    color: var(--market-main);
    font-size: 16px;
  }
}

.load-more-btn {
  float: right;
}

.back-to-top-footer {
  margin: 15px 0 50px 0;
  text-align: center;

  // Homepage modifier
  &--homepage {
    margin: 5px 0 5px 0;

    @media(min-width: @screen-sm-min) {
      margin: 15px 0 15px 0;
    }
  }
}

div.col-5-back-to-top {
  padding-right: 0;
}

div#back-to-top { display: inline; }
// </editor-fold>


// =====================================================================================================================
// Homepage
//
// <editor-fold>
// =====================================================================================================================
.homepage {

  &.container {
    margin-bottom: 10px;

    @media(min-width: @screen-sm-min) {
      margin-bottom: 30px;
    }

    .container--zero-padding-xs();
  }

  .homepage-flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: @screen-sm-min) {
      flex-direction: row;
    }
  }
  .homepage-section {

    .homepage-section-header {
      text-align: center;
      h2 {
        color: var(--market-main);
        font-weight: 900;
        margin-bottom: 10px;
        margin-top: 0px;
        text-transform: uppercase;
        font-size: 18px;

        @media (min-width: @screen-sm-min) {
          font-weight: 700;
          font-size: 30px;
        }
      }

      &--with-controls {
        display: flex;
        @media(min-width: @screen-sm-min) {
          height: 70px;
        }
      }

      &--no-subtitle {
        @media(min-width: @screen-sm-min) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.release-grid {
  .release-grid-indicator { color: var(--market-main); }
  .release-list-indicator { color: @line-gray; }
  .release-item-thumbnail-image__list { display: none; }
}

.release-list {
  .release-list-indicator { color: var(--market-main); }
  .release-grid-indicator { color: @line-gray; }
  .release-item-thumbnail-image__grid { display: none; }
}
// </editor-fold>


// =====================================================================================================================
// Release Grid and List Mixins
//
// <editor-fold>
// =====================================================================================================================
.release-grid-mixin(@sm-to-md: 2, @lg: 4) {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0;
  flex-direction: row;
  .release-item {
    padding: 0!important;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }

    // * -> sm: 1-wide
    width: 100%;

    a {
      width: 50%;
    }
    // sm <-> md
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
      border-left: solid 15px #EBEBEB;
      border-right: solid 15px #EBEBEB;
      flex-direction: column;
      margin-bottom: 30px;
      width: calc(100% / @sm-to-md);
      &:nth-last-child(-n+@{sm-to-md}) {
        margin-bottom: 0;
      }
      &:nth-child(@{sm-to-md}n) {
        border-right: none;
      }
      &:nth-child(@{sm-to-md}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }
    @media (min-width: @screen-lg-min) {
      border-left: solid 15px #EBEBEB;
      border-right: solid 15px #EBEBEB;
      flex-direction: column;
      margin-bottom: 30px;
      width: calc(100% / @lg);
      &:nth-last-child(-n+@{lg}) {
        margin-bottom: 0;
      }
      &:nth-child(@{lg}n) {
        border-right: none;
      }
      &:nth-child(@{lg}n+1) {
        border-left: none;
      }
      a {
        width: 100%;
      }
    }
    summary {
      display: none;
    }
    .release-list-actions-container {
      display: none;
    }
    .row {
      margin: unset!important;
    }
    .col-xs-12 {
      padding: unset!important;
    }
    div.release-information {
      //width: 50%;
      @media(min-width: @screen-sm-min) {
        width: 100%;
      }
      .release-information-row {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-template-areas: "title"
                             "pub-date";
        @media(min-width: @screen-sm-min) {
          grid-template-areas: "pub-date"
                               "title";
        }
      }
      .pub-date {
        grid-area: pub-date;
        color: @black!important;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        text-transform: uppercase;
        font-size: 12px;
        @media(min-width: @screen-sm-min) {
          margin-top: inherit;
          margin-bottom: 5px;
          font-size: 14px;
        }
        .release-grid__actions {
          display: flex;
          a {
            font-size: 16px;
            margin-left: 5px;
          }
        }
      }
      .content-title-container {
        grid-area: title;
      }
      .summary-container {
        display: none;
      }
    }
    figure {
      width: 100%;
    }
    .content-title {
      margin: 0;
      a {
        color: var(--market-main);
        font-size: 14px;
        @media(min-width: @screen-sm-min) {
          font-size: 16px;
        }
      }
    }
    // Hide the img we use for list layout
    .release-list__photo {
      display: none;
    }
  }
}

.release-list-mixin {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;

  .release-item {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;

    & > a {
      flex: 0.25;
      width: unset;

      figure {
        width: 100%;
        height: 100%;
      }
    }

    & > div.release-information {
      flex: 1;
      width: unset;
    }

    & > div.release-information--with-thumbnail {
      flex: 0.75;
    }

    img {
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }

    .content-title {
      a {
        color: var(--market-main);

        @media(min-width: @screen-sm-min) {
          font-size: 20px;
        }

        @media(min-width: @screen-md-min) {
          font-size: 22px;
        }
      }
    }

    .pub-date {
      color: @black;
      text-transform: uppercase;
      .release-grid__actions {
        display: none;
      }
    }

    // Hide the cropped figure+bg image photo we use for grid layout
    .release-grid__photo {
      display: none;
    }

  }
}

/*
.release-grid .release-container {
  // No matter what, if being asked to display a release-grid, do it.
  .release-grid-mixin(2, 4);
}

.release-list .release-container {
  // If being asked to display a release-list on sm or lower, display a release-grid instead.
  @media(max-width: @screen-xs-max) {
    .release-grid-mixin(2, 4);
  }

  // Otherwise, display the release-list style
  @media(min-width: @screen-sm-min) {
    .release-list-mixin();
  }
}
*/

.release-list .release-container {
  .release-list-mixin();
}

// </editor-fold>


// =====================================================================================================================
// Content Type Tab Bar
//
// <editor-fold>
// =====================================================================================================================
#content-type-tab-bar {
  align-items: center;
  background: transparent;
  display: flex;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .nav-tabs {
    border: none;
    .content-type {
      text-align: center;
      z-index: 2;
      a {
        background: transparent;
        border: none;
        cursor: pointer;
        color: var(--market-main);
        font-size: 18px;
        margin: 0 10px;
        padding: 15px 0;
        @media (min-width: @screen-sm-min) {
          font-size: 32px;
        }
      }
    }
    .content-type:first-child a {
      margin-left: 0;
    }
    .content-type a:after {
      content: "";
      position: absolute;
      transition: transform 100ms ease-in-out;
      width: 75px;
      height: 5px;
      margin-left: 0;
      margin-top: 5px;
      display: block;
      transform: translateY(-15px) scale(0,0);
      overflow: hidden;
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
    }
    .content-type:hover a:after {
      transform: translateY(0) scale(1, 1);
      background-color: #ffc843;
    }
    .content-type.active a:after {
      content: "";
      display: block;
      position: absolute;
      width: 42px;
      height: 5px;
      background-color: #ffc843;
      margin-left: 0;
      margin-top: 5px;
      transform: translateX(0);
      @media (min-width: @screen-sm-min) {
        width: 75px;
      }
    }
  }
  .custom-media-actions {
    .custom-btn-group {
      #daterange-desktop, .dropdown-toggle {
        align-items: center;
        background: transparent;
        border: none;
        box-shadow: none;
        color: var(--market-main);
        display: flex;
        font-size: 14px;
        padding: 5px 0;
        i {
          font-size: 18px;
          margin-left: 10px;
        }
      }
      #date-range-dropdown-container.open {
        #daterange-desktop {
          color: var(--market-main);
        }
      }
    }
    .custom-btn-group:first-child  {
      margin-right: 20px;
    }
    .custom-btn-group.open  {
      ul.site-dropdown-menu {
        border: none;
        border-radius: 0;
        li a {
          color: @black;
          padding: 3px 12px;
          &:active, &:hover {
            background-color: @white;
            color: var(--market-main);
          }
        }
      }
      ul#sort-options {
        left: -50px;
        right: 0;
        top: 51px;
        margin: 0;
        min-width: 115px;
        @media (max-width: @screen-xs-max) {
          left: -90px;
          right: 0;
        }

        @media (min-width: @screen-sm-min) {
          top: 83px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          margin-top: -15px;
          left: ~"calc(86% - 10px)";
          border: solid 10px transparent;
          border-bottom-color: #FFF;
          z-index: 1;

          @media (min-width: @screen-sm-min) {
            left: ~"calc(66% - 10px)";
          }
        }
      }
    }
  }
}

.daterangepicker.show-calendar.opensleft:after {
  right: 48px;
}
// </editor-fold>


// =====================================================================================================================
// Date Range Picker
//
// <editor-fold>
// =====================================================================================================================
#daterange-desktop__dropdown {
  border-radius: 0;
  border: none;
  padding: 10px;

  @media(min-width: @screen-sm-min) {
    top: 83px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    margin-top: -15px;
    border: solid 10px transparent;
    border-bottom-color: #FFF;
    z-index: 1;

    left: ~"calc(87% - 10px)";

    @media(min-width: @screen-sm-min) {
      left: ~"calc(68% - 10px)";
    }
  }

  .refine-search-quick-filter {
    background-color: @light-gray;
    border: none;
    color: @black;
    margin-bottom: 10px;
    padding: 5px 10px;
    text-align: left;
    width: 100%;
    transition: none;
    &--active, &:hover {
      background-color: var(--market-main);
      color: @white;
    }
  }
  .refine-search-quick-filter.custom-range {
    background-color: var(--market-main);
    color: @white;
  }
  .input-group {
    margin-bottom: 10px;
    .form-control {
      border-color: @light-gray;
      box-shadow: none;
    }
    .input-group-addon {
      border-color: @light-gray;
      border-radius: 0;
      padding: 8px;
      cursor: pointer;
    }
  }
  .btn-group {
    width: 100%;
    .apply-date-filter, .clear-date-filter {
      border-radius: 0;
      width: 50%;
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Release Show Content Type Tab Bar
// <editor-fold>
// =====================================================================================================================
#content-type-tab-bar.release-content-type-tab-bar {
  align-items: normal;
  background-color: @white;
  flex-direction: column;
  margin: 30px 0;
  padding: 0;
  .nav-tabs .content-type a {
    padding: 0 0 10px;
    @media(min-width: @screen-sm-min) {
      background-color: @white;
    }
  }
  .nav-tabs .content-type.active>a {
    @media(min-width: @screen-sm-min) {
      background-color: @white;
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Modal Dialogs
//
// <editor-fold>
// =====================================================================================================================
.modal {
  text-align: center;
}

@media(min-width: @screen-sm-min) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  margin: 50px auto!important;
  z-index: 9999;
  @media(min-width: @screen-sm-min) {
    margin: 30px auto!important;
  }
}

.modal-dialog {
  .search-modal-dialog {
    @media(max-width: 563px) {
      width: 300px !important;
    }
  }
}

#searchContentType {
  float: none !important;
  @media(max-width: 563px) {
    min-width: 200px !important;
  }
}

div.modal-body {
  div#prev, div#next {
    color: @text-gray;
    cursor: pointer;
  }
}
.modal-dialog-preview {
  .photo-modal-image {
    max-height: 768px;
  }
  .content-title h2 {
    color: @black;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .modal-footer {
    padding: 0 15px 15px;
    border-top: none;
  }
}
.modal-dialog {
  .modal-content {
    background-color: @dark-gray;
    border-radius: 0;
    .modal-header {
      border: none;
      button.close {
        color: @black;
        font-weight: normal;
        opacity: 1;
        text-shadow: none;
        text-transform: uppercase;
        &:hover, &:focus {
          color: @market-hover;
        }
        i {
          font-size: 20px;
        }
      }
    }
    .modal-body {
      padding: 0 15px;
    }
  }
}

.icon-button {
  border: none;
  color: @line-gray;
  display: inline-flex;
  font-size: 2rem;
  margin: 0 10px 3px 0;
  text-align: center;
  transition: ease-in-out .25s;

  &:focus, &:hover {
    color: var(--market-main);
  }
}

.icon-button-label {
  font-size: 1.4rem;
  margin-right: 5px;
}


#media-modal {
  z-index: 9999;
  .modal-dialog {
    .modal-content {
      .modal-body {
        .photo-preview-container, .video-preview-container {
          padding: 0;
        }
        .content-title h2 {
          color: @white;
        }
        .content-caption {
          color: #b3b3b3;
        }
        .content-publication-date {
          color: #b3b3b3;
        }
        .modal-channels {
          margin: 0 0 10px;
        }
        img {
          margin-left: auto;
          margin-right: auto;
        }
        .modal-actions-container {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 0;
          top: 0;

          .dropdown-menu {
            border-radius: 0;
            left: auto;
            right: 0;
          }
        }
      }
    }
  }

  // Icons along right side of photo and video modal dialogs
  .modal-actions-button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: @white;
    font-size: 26px;
    margin-bottom: 3px;
    opacity: .5;
    padding: 5px 10px;
    text-align: center;
    transition: ease-in-out .25s;
    z-index: 9999;
    &:hover, &:focus {
      background: var(--market-main);
      color: @white;
      opacity: 1;
    }
    @media (max-width: @screen-xs-max) {
      font-size: 14px;
    }
  }

  .modal-pagination.modal-pagination-prev {
    &.disabled {
      color: rgba(255,255,255,0.25);
      cursor: not-allowed!important;
    }
  }

  .modal-pagination.modal-pagination-next {
    &.disabled {
      color: rgba(255,255,255,0.25);
      cursor: not-allowed!important;
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// License Modal
//
// <editor-fold>
// =====================================================================================================================
#licenseModal {
  z-index: 999999;
}

#licenseModal .modal-dialog {
  .modal-content {
    background-color: @white;
    .modal-header {
      h3 {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    .btn-primary {
      background-color: var(--market-main);
      border: none;
      border-radius: 0;
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Channels
//
// <editor-fold>
// =====================================================================================================================
#channel-banner {
  min-height: 190px;
  margin-top: 100px;
  background-color: black;
  margin-bottom: 25px;
  padding-top: 25px;
  #social-filter {
    cursor: pointer;
    margin-top: 50px;
    color: white;
    border: 1px solid white;
    width: 135px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    i.fa {
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
  }
  ul#social-subchannels {
    width: 135px;
    list-style: none;
    padding: 0px;
    float: right;
    li {
      width: 135px;
      height: 40px;
      border: 1px solid white;
      text-align: center;
      padding-top: 10px;
    }
  }
  background-size: cover!important;
  display: flex;
  display: -ms-flexbox;
  h1#channel-title {
    color: @white;
    line-height: 108px;
    font-weight: lighter;
    padding-left: 13px;
    text-shadow: 0 2px 7px rgba(0,0,0,0.3);
  }
  @media (max-width: @screen-xs-max) {
    div { padding-left: 0px; }
    height: 4em;
    h1#channel-title {
      padding-left: 6px!important;
      font-size: 1.7em;
      margin-top: 12px;
      line-height: 30.8px;
    }
  }
  img {
    width: 100%;
  }
}

ul.secondary-nav {
  border: none;
  li.active {
    border-bottom: 5px solid black!important;
    a {
      border-bottom-color: transparent!important;
      border-top-color: transparent!important;
      border-left-color: transparent!important;
      border-right-color: transparent!important;
      color: @black;
    }
  }
  li {
    margin-left: 10px;
    margin-right: 10px;
    a {
      border-color: transparent!important;
      background-color: transparent!important;
      color: #555555;
      padding-bottom: 3px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      &:hover, &:focus {
        color: @market-hover;
      }
    }
  }
}

// Embargoed Channel Show Page
div.embargoed-channel {
  div.banner-component {
    width: 100%;
    h1.release-banner-title {
      text-align: center;
    }
    div.channel-embargo-notice {
      background-color: #CC0000;
      padding: 20px;
      text-align: center;
      color: white;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  div.custom-media-actions { display: none; }

  div.release-list-actions-container {
    a.basket-action-visible, a.icon-button.facebook, a.icon-button.twitter, a.icon-button.linkedin { display: none!important; }
  }
}
// </editor-fold>


// =====================================================================================================================
// Releases
//
// <editor-fold>
// =====================================================================================================================
.tab-content {
  .release-item {
    position: relative;
    background-color: @white;
    border-bottom: 1px solid @bswh-gray;
    margin-bottom: 10px;
    padding: 0 0 10px;
    @media (min-width: @screen-md-min) {
      margin-bottom: 20px;
      padding: 0 0 20px;
    }
    .release-information {
      order: 1;
    }
    .release-photo-container {
      order: 2;
    }
    figure.release-photo {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 180px;
      overflow: hidden;
      @media (max-width: @screen-xs-max) {
        height: 25vw;
        width: 100%;
      }
    }
    img.release-photo {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .release-photo.embargoed {
      border: solid 1px var(--market-main);
      padding: 5px;
    }
    .release-information {
      .content-title {
        margin: 0 0 10px
      }
      @media (max-width: @screen-xs-max) {
        height: auto;
      }
      .content-type-icon {
        font-size: 22px;
        margin-right: 5px;
      }
      .fa-facebook.content-type-icon {
        color: #3b5998;
      }
      .fa-twitter.content-type-icon {
        color: #55acee;
      }
      .fa-youtube-play.content-type-icon {
        color: #bb0200;
      }
      summary {
        margin-top: 5px;
      }
      .release-summary-continue {
        size: 14px;
        margin-left: -4px;
      }
      .release-list-actions-container {
        min-height: 30px;
        .release-list-actions {
          @media (min-width: @screen-xs-max) {
            margin-top: 10px;
          }
        }
      }
      div.presskit-member {
        background-color: @light-gray;
        padding: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
        display: inline-block;
      }
    }
    .release-information.embargoed {
      .content-title a {
        color: var(--market-main)!important;
        &:before {
          font-family: "Font Awesome 5 Pro";
          content: "\f05e";
          display: inline-block;
          padding-right: 5px;
        }
      }
      summary {
        color: var(--market-main)!important;
      }
      .published-at {
        color: var(--market-main)!important;
      }
      .release-list-actions {
        display: none;
      }
    }
  }
  .release-item:last-child {
    //margin-bottom: 5px;
  }
}

// Hide redundant back-to-tops in channel list presentations
.channel-presentation-style--list .paginated-content-container .content-back-to-top-footer {
  display: none;
}

.content-back-to-top-footer {
  a.back-to-top {
    float: left;
    margin-bottom: 30px;
  }
}

.nav {
  .release-action {
    float: right;
    a {
      padding: 0;
      margin-left: 10px;
      text-transform: uppercase;
      &:focus, &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

div.publication {
  margin-bottom: 1em;
  .published-at {
    &:before {
      margin-right: 5px;
    }
    time {
      font-weight: bold;
    }
  }
  span.dateline {
    font-weight: normal;
  }
}

.header-banner-container {
  background-color: white;
  background-position: center;
  @media (min-width: @screen-lg-min) {
    min-height: var(--banner-min-height);
  }
  &:before {
    border-bottom: 89px solid var(--market-main);
    border-right: 89px solid transparent;
    left: 0;
    position: absolute;
    height: 0;
    width: 0;
    @media (min-width: @screen-sm-min) {
      border-bottom: 114px solid var(--market-main);
      border-right: 114px solid transparent;
    }
    @media (min-width: @screen-md-min) {
      border-bottom: 134px solid var(--market-main);
      border-right: 134px solid transparent;
    }
    @media (min-width: @screen-lg-min) {
      border-bottom: 200px solid var(--market-main);
      border-right: 200px solid transparent;
    }
  }
}

.header-banner {
  position: relative;
  @media (min-width: @screen-lg-min) {
    min-height: var(--banner-min-height);
  }
  picture {
    filter: brightness(50%);
    min-height: 138px;
    background-color: black;
    width: 100%;
    img { width: 100%; }
  }
  .banner-title-outer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > .container-fluid { flex: 1; }

    @media (min-width: @screen-md-min) {
      min-height: var(--banner-min-height);
    }
    @media (max-width: @screen-xs-max) {
      .container {
        width: 100%;
      }
    }
    .banner-component {
      padding-top: 20px;
      padding-bottom: 20px;
      .channel-filter {
        border: 1px solid @white;
        background: rgba(0, 0, 0, 0.2)!important;
        color: @white;
        &:focus {
          outline: none;
        }
      }
    }
    .banner-component--title h1 {
      color: @white;
      font-weight: 700;
      font-size: 18px;
      margin: 15px 0;
      @media (min-width: @screen-sm-min) {
        font-size: 40px;
      }
    }
    @media (max-width: @screen-sm-max) {
      .banner-component-title {
        padding-bottom: 0;
      }
    }
  }
  .banner-title-outer-container--no_banner_image {
    background-color: #000;
    position: static;
  }
  .release-banner-container {
    padding-left: 0px;
    padding-top: 20px;
    max-width: 1025px;
    h1.release-banner-title {
      margin: 0;
      color: var(--market-main);
      text-transform: uppercase;
      font-weight: 600;
      @media (max-width: @screen-xs-max) {
        font-size: 26px;
      }
    }
    .subtitle {
      color: #b3b3b3;
      font-style: italic;
      font-size: 22px;
      font-weight: 400;
      padding-top: 10px;
      line-height: 1.2;
      @media (max-width: @screen-xs-max) {
        font-size: 18px;
      }
    }
  }
  .banner-component--search-entire-site {
    margin-top: 45px;
    padding-left: 0;
    button.search-entire-site {
      float: right!important;
      background-color: transparent;
      border: 1px solid @white;
      padding: 5px 10px;
      color: @white;
      font-size: 14px;
      text-transform: uppercase;
      &:hover, &:focus {
        background-color: @white;
        color: @black;
      }
    }
    @media (max-width: @screen-sm-max) {
      margin-top: 0;
      padding-bottom: 15px;
    }
  }
}

article#release {
  .subtitle {
    font-size: 18px;
    font-weight: 100;
    padding: 0px 0px 15px;
  }
  .release-list-actions {
    font-weight: 100;
    padding: 0;
    text-transform: uppercase;
    float: right;
    font-size: 22px;
    margin-top: -75px;
  }
  .published-at { font-size: 1.8rem; }
  .back-to-top-container {
    text-align: center;
  }
  div.content-back-to-top-footer {
    .footer-container {
      display: flex;
      justify-content: center;
      .back-to-top {
        margin-top: 20px;
      }
    }
  }
}

.tab-content {
  .release-item {
    .release-information {
      padding-left: 0;
    }
  }
  .release-item.release-item--with-thumbnail {
    .release-information {
      padding-right: 15px;
    }
  }
}

.thumb-attachment-widget {
  border-bottom: 1px solid @line-gray;
  margin-bottom: 20px;
  padding-bottom: 10px;
  .sidebar-header {
    h3 {
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 20px;
      margin-top: 2px;
      text-transform: uppercase;
      a {
        color: @black;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: @market-hover;
        }
      }
    }
  }
  .document {
    .filename {
      .dont-break-out();
      color: #595959;
      font-weight: 100;
      transition: ease-in-out .25s;
      &:focus, &:hover {
        color: #b2b2b2;
      }
    }
  }
}

#related-photos, #related-videos {
  header h2 {
    font-weight: normal;
    text-transform: uppercase;
  }
}

.fa-colorized {
  &.fa-file-excel-o { color: #227446; }
  &.fa-file-word-o { color: #2A579A; }
  &.fa-file-powerpoint-o { color: #B84729; }
  &.fa-file-pdf-o { color: #FF0001; }
}

#related-news, #stories, #social {
  .release-item {
    .release-list-actions {
      padding: 0 15px;
    }
  }
}

#related-news-widget {
  .related-release-item {
    margin-bottom: 15px;
    .content-title {
      margin-bottom: 5px;
      margin-top: 0;
    }
    .published-at {
      font-weight: 100;
    }
  }
}

#links-widget {
  .ion-link {
    color: @black;
    font-size: 24px;
  }
  .document {
    font-size: 14px;
    margin-left: 5px;
  }
}

p.quote {
  padding: 2em 4em;
  background: #e6e6e6;
  margin: 2em 4em 2.5em;
  @media (max-width: @screen-xs-max) {
    padding: 1em;
    background: #e6e6e6;
    margin: 2em;
  }
}

.bullet-list {
  background-color: #ebebeb;
  padding: 15px 15px 15px 43px;
  border-radius: 4px;
}
// </editor-fold>


// =====================================================================================================================
// Release Show
//
// <editor-fold>
// =====================================================================================================================
.release-body {
  font-size: 16px;
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
  .header-banner-container {
    background-color: transparent;
  }
  .tab-pane.channel-tab {
    margin-top: 0;
  }
}

.release-body.release-body__banner {
  width: 728px;
  @media (min-width: @screen-md-min) {
    width: 940px;
  }
  @media (min-width: @screen-lg-min) {
    width: 992px;
  }

  padding-top: 20px;
  padding-bottom: 20px;
}

.it-contains-the-triangle {
  position: relative;
  overflow: hidden;
}

.release-body__title-container {
  align-items: center;
  background-color: #258EC2;
  display: flex;
  min-height: 130px;

  &:before {
    border-bottom: 1200px solid var(--market-main);
    border-right: 1200px solid transparent;
    left: 0;
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
  }
  .release-body {
    position: relative;
  }
}

.release-body__title {
  color: @white;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 32px;
  }
  @media (min-width: @screen-md-min) {
    font-size: 36px;
  }
}

.release-body__subtitle {
  color: @bswh-yellow;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 22px;
  }
  @media (min-width: @screen-md-min) {
    font-size: 26px;
  }
}

.release-body__share-add-download-buttons {
  margin-top: 30px;
  .social-media-container .social-media a:first-child {
    padding-left: 0;
  }
  .social-media-container .social-media a.btn, .social-media-container .social-media a.btn.dropdown-toggle {
    align-items: center;
    display: flex;
    font-size: 14px;
    i {
      font-size: 20px;
      margin-left: 5px;
    }
  }
}

.bottom__share-add-download-buttons {
  border-bottom: 1px solid @light-gray;
  border-top: 1px solid @light-gray;
  margin: 20px 0;
  padding: 20px 0;
}

@media (max-width: @screen-xs-max) {
  .bottom__share-add-download-buttons {
    display: flex;
    justify-content: center;
  }
}

.release-body__channel-list {
  align-items: center;
  border-bottom: 1px solid @light-gray;
  display: flex;
  margin: 0 0 20px;
  padding: 0 0 20px;
  i {
    color: var(--market-main);
    font-size: 24px;
  }
  h5 {
    color: var(--market-main);
    font-size: 14px;
    font-weight: 600;
    margin: 0 10px 0;
    text-transform: uppercase;
  }
  ul.assigned-channels {
    margin: 0;
  }
}

@media (max-width: @screen-xs-max) {
  .release-body__channel-list {
    flex-wrap: wrap;
    justify-content: center;
    ul.assigned-channels {
      display: flex;
      list-style: none;
      justify-content: center;
      padding: 10px 0 0;
      width: 100%;
    }
  }
}

.release-body__dateline, .release-body__published-at {
  font-size: 12px;
  @media (min-width: @screen-sm-min) {
    font-size: 18px;
  }
}

#release-main {
  text-align: justify;

  .well {
    padding: 15px 15px 15px 30px;
    ul {
      padding: 0;
      margin: 0;
    }
  }
}

.release-quote__pull-left, .release-quote__pull-right {
  background-color: var(--market-main);
  color: @white;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  @media (min-width: @screen-md-min) {
    width: 350px;
  }
  p {
    font-family: Adamina;
    font-size: 24px;
    margin: 0;
  }
  h3 {
    color: @white;
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px;
    text-transform: uppercase;
  }
  h4 {
    color: @white;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    text-transform: uppercase;
  }
}

.release-quote__pull-left, .release-image__pull-left {
  float: none;
  margin-right: 0;
  @media (min-width: @screen-md-min) {
    float: left;
    margin-right: 20px;
  }
}

.release-quote__pull-right, .release-image__pull-right {
  float: none;
  margin-left: 0;
  @media (min-width: @screen-md-min) {
    float: right;
    margin-left: 20px;
  }
}

.release-image__pull-left, .release-image__pull-right {
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: @screen-md-min) {
    width: 400px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
#release-boilerplate {
  h4 {
    color: var(--market-main);
    font-weight: 600;
  }
}
// </editor-fold>


// =====================================================================================================================
// Related Releases
//
// <editor-fold>
// =====================================================================================================================
.release-related-news__outer {
  width: 100%;
  padding: 10px;
  background-color: @lighter-gray;

  @media(min-width: @screen-sm-min) {
    .container-fixed();
    padding: 0 0 30px;
  }

  @media (min-width: @screen-md-min) {
    width: calc(@container-md - 30px);
  }

  @media (min-width: @screen-lg-min) {
    width: calc(@container-lg - 180px);
  }
}


.release-related-news__inner {
  background-color: @lighter-gray;
  padding: 10px 0;

  @media (min-width: @screen-sm-min) {
    padding: 30px;
    padding: 30px 30px 0;
  }

  .more-btn-container {
    text-align: center;
    margin-top: 10px;

    @media(min-width: @screen-sm-min) {
      margin-top: 30px;
    }
    ul.dropdown-menu-center {
      left: 50% !important;
      right: auto !important;
      text-align: center !important;
      transform: translate(-50%, 0) !important;
    }
    .more-btn-dropdown-container-mobile {
      ul.dropdown-menu {
        border: none;
        border-radius: 0;
      }
    }
    a {
      font-size: 16px;
      text-transform: uppercase;
      i {
        color: var(--market-main);
      }
    }
  }

}



#related-releases {
  .homepage-in-the-news-item {
    border-bottom: 0;

    @media(max-width: @screen-xs-max) {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.related-release-item-container {
  padding: 0;

  @media(min-width: @screen-sm-min) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

.related-release-item {
  flex: 1;
  display: block;
  grid-template-rows: 100%;
  grid-template-columns: 36% 64%;
  grid-template-areas: "image link";
  grid-gap: 10px;
  border-bottom: solid 1px @bswh-light-gray;
  padding-bottom: 10px;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  @media(min-width: @screen-sm-min) {
    grid-template-areas: "all";
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    padding: 0 5px;
    border-bottom: none;

    width: 33%;
    flex: 0 0 auto;

    &:nth-child(3n+1) { // 1, 4, 7, 10, 13, 16...
      padding-left: 0;
      padding-top: 0;
    }

    &:nth-child(n+4) { // 5, 6, 7, 8...
      margin-top: 10px;
    }

    &:nth-child(3n+0) { // 0, 3, 6, 9, 12...
      //border-bottom: none;
      padding-right: 0;
    }
  }
}

.related-release-item__link {
  color: @black;
  grid-area: link;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 0;
  //font-family: @header-font-stack;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;

  @media(min-width: @screen-sm-min) {
    color: @white;
    grid-area: all;
    justify-content: flex-end;
    padding: 10px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  @media(min-width: @screen-md-min) {
    font-size: 1.5rem;
    padding: 10px;
  }
  .pub-date {
    color: @black;
    font-size: 1.4rem;
    font-weight: 500;
    text-shadow: none;
  }
  .assigned-channels li {
    font-size: .8rem;
    font-weight: 500;
    text-shadow: none;
    @media(min-width: @screen-md-min) {
      font-size: 1rem;
    }
  }
}

.related-release-item__image {
  background-color: #CCCCCC;
  grid-area: image;
  @media(min-width: @screen-sm-min) {
    grid-area: all;
  }
}

// </editor-fold>


// =====================================================================================================================
// Photos
//
// <editor-fold>
// =====================================================================================================================
.grid {
  margin-bottom: 40px;
  /* reveal grid after images loaded */
  &.are-images-unloaded {
    opacity: 0;
  }

  .grid__item, .grid__col-sizer {
    width: 49.5%;
    @media (min-width: @screen-sm-min) {
      width: 32.5%;
    }
    @media (min-width: @screen-md-min) {
      width: 24.25%;
    }
  }

  .grid__gutter-sizer {
    width: 1%;
    @media(min-width: @screen-sm-min) {
      width: 1.25%;
    }
    @media (min-width: @screen-md-min) {
      width: 1%;
    }
  }

  .grid__item {
    background-color: @white;
    margin-bottom: 1%;
    transition: box-shadow 80ms;
    @media(min-width: @screen-sm-min) {
      margin-bottom: 1.25%;
    }
    @media (min-width: @screen-md-min) {
      margin-bottom: 1%;
    }
    img {
      display: block;
      max-width: 100%;
    }
    &.embargoed {
      .content-information {
        .grid-item__actions {
          display: none;
        }
      }
    }
    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.95);
      width: 100%;

      &.collapsing {
        transition: none;
        display: none;
      }
    }
    .content-information {
      padding: 10px 0 5px;
      .content-title {
        margin: 0 0 10px;
        a {
          color: @bswh-dark-blue;
          font-size: 12px;

          &:hover, &:focus {
            color: @bswh-light-blue;
          }

          @media(min-width: @screen-sm-min) {
            font-size: 16px;
          }
        }
      }
      a {
        color: #b3b3b3;
        font-size: 20px;
        margin-right: 5px;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: var(--market-main);
        }
        i {
          font-size: 20px;
        }
      }
    }
  }

  .content-back-to-top-footer > div {
    bottom: -40px;
    position: absolute;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.download-overlay {
  padding-top: 30px;

  // Bootstrap 3 Collapse
  &.collapse.in { display: flex; }

  flex-direction: column;
  text-align: center;

  &__header {
    color: var(--market-main);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
  }

  &__list {
    flex: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;

    font-size: 16px;
    line-height: 30px;
  }

  &__cancel {
    height: 30px;
    color: black;
    text-transform: uppercase;
  }
}

.photo-embargoed {
  .embargoed {
    background: var(--market-main);
    color: white !important;
    margin: 15px 0 0;
    padding: 10px 15px;
    text-transform: uppercase;
    &:before {
      content: "\f05e";
      display: inline-block;
      font-family: fontawesome;
      padding-right: 5px;
    }
  }

  .modal-footer {
    display: none;
  }
}

.latest-photos {
  .item {
    overflow: hidden;
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      min-height: 320px;
    }
    a.homepage-photo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: block;
      height: 250px;
      @media (min-width: @screen-md-min) {
        height: 120px;
      }
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// Photo Show
//
// <editor-fold>
// =====================================================================================================================
.photo-show, .video-show {
  .release-body__share-add-download-buttons {
    margin-bottom: 30px;
  }
  .release-body__title {
    font-size: 18px;
    @media (min-width: @screen-sm-min) {
      font-size: 22px;
    }
    @media (min-width: @screen-md-min) {
      font-size: 28px;
    }
  }
  .release-body__subtitle {
    @media (min-width: @screen-sm-min) {
      font-size: 16px;
    }
    @media (min-width: @screen-md-min) {
      font-size: 20px;
    }
  }
}
// </editor-fold>

// =====================================================================================================================
// Videos
//
// <editor-fold>
// =====================================================================================================================
.list__item.video-item {
  display: grid;
  @media (max-width: @screen-xs-max) {
    grid-template-areas: "img" "info";
  }
  @media (min-width: @screen-sm-min) {
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 2rem;
    margin-bottom: 2.5rem;
  }
  .homepage-video-thumbnail {
    @media (max-width: @screen-xs-max) {
      grid-area: img;
    }
  }
  &.embargoed {
    .content-information {
      .grid-item__actions {
        display: none;
      }
    }
  }
  .content-title--mobile {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 30%);
    font-size: 14px;
    grid-area: img;
    align-self: end;
    line-height: 1.3;
    margin: 0;
    padding: 3rem 2rem 2rem;
    z-index: 9;
    @media(min-width: @screen-sm-min) {
      display: none;
    }
    a {
      color: @white;
      &:hover, &:focus {
        color: @bswh-light-blue;
      }
    }
  }
  .content-information {
    @media (max-width: @screen-xs-max) {
      display: grid;
      grid-area: info;
      margin: 10px 0 2rem 0;
      grid-template-columns: 1fr auto;
      grid-column-gap: 1rem;
    }
    .content-title {
      display: none;
      @media(min-width: @screen-sm-min) {
        display: block;
        margin: 0 0 10px;
      }
      a {
        color: @bswh-dark-blue;
        &:hover, &:focus {
          color: @bswh-light-blue;
        }
        @media(min-width: @screen-sm-min) {
          font-size: 22px;
        }
      }
    }
    .content-caption {
      color: @bswh-black;
      font-size: 12px;
      margin: 0;
      @media(min-width: @screen-sm-min) {
        font-size: 14px;
        margin: 0 0 10px;
      }
    }
    a {
      color: @line-gray;
      font-size: 20px;
      margin-right: 5px;
      transition: ease-in-out .25s;
      &:focus, &:hover {
        color: var(--market-main);
      }
      i {
        font-size: 20px;
      }
    }
  }
}

.video__divider {
  @media(min-width: @screen-sm-min) {
    border-bottom: 1px solid @bswh-light-gray;
    margin: 0 0 2.5rem;
  }
  @media(min-width: @screen-md-min) {
    margin: 0 10rem 2.5rem;
  }
}

.play-button-overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  i {
    color: rgba(255,255,255,0.85);
    font-size: 40px;
    transition: ease-in-out .25s;
  }

  &:hover {
    i {
      color: var(--market-main);
    }
  }
}

@media (max-width: @screen-xs-max) {
  .video-item:last-child {
    .content-information {
      border-bottom: none;
    }
  }
}

.video-item.embargoed {
  .content-information {
    .content-title {
      a {
        color: var(--market-main)!important;
        &:before {
          font-family: fontawesome;
          content: "\f05e";
          padding-right: 5px;
        }
      }
    }
    .grid-item__actions {
      display: none;
    }
  }
}

.video-embargoed {
  .embargoed {
    background: var(--market-main);
    color: white !important;
    margin: 15px 0 0;
    padding: 10px 15px;
    text-transform: uppercase;
    &:before {
      content: "\f05e";
      display: inline-block;
      font-family: fontawesome;
      padding-right: 5px;
    }
  }
  .modal-footer {
    display: none;
  }
}
// </editor-fold>


// =====================================================================================================================
// Basket
//
// <editor-fold>
// =====================================================================================================================
#basket-tab-content {
  .tab-pane {
    margin-bottom: 30px;
  }
}

#share-basket-btn, #clear-basket-btn {
  background: transparent;
  border: none;
  color: @white;
  float: right;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    color: @bswh-yellow;
  }
  i {
    color: @bswh-yellow;
    font-size: 16px;
  }
}

#clear-basket-btn {
  margin-left: 10px;
  i { padding-top: 3px; }
}



.basket-action-hidden { display: none!important; }

#basket-tab-content {
  .custom-actions-nav {
    .basket-download {
      margin-right: 15px;
    }
  }
}

.custom-basket-actions {
  a {
    color: var(--market-main);
    margin-right: 10px;
    &:hover {
      color: @bswh-light-blue;
    }
  }
  a.basket-remove {
    margin-right: 0;
  }
}

.empty-basket-block--visible {
  p {
    font-size: 18px;
    margin: 30px 0 10px;
  }
}

header#basket {
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 34px;
    }
  }
}

.basket__banner-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  .banner-component--title h1 {
    margin: 0;
  }
  i.fa-folder {
    color: @bswh-yellow;
    font-size: 16px;
    margin-left: 0;
    position: relative;
    @media (min-width: @screen-sm-min) {
      font-size: 32px;
    }
    @media (min-width: @screen-md-min) {
      font-size: 24px;
      margin-left: 10px;
      position: absolute;
    }
  }
}

div.basket-actions {
  a {
    padding: 10px 0;
  }
}

.basket-content-type-tab-bar-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.basket-content-type-tab-bar-actions {
  @media (max-width: @screen-xs-max) {
    margin-top: 15px;
    width: 100%;
  }
}
// </editor-fold>


// =====================================================================================================================
// Forms
//
// <editor-fold>
// =====================================================================================================================
.form-control {
  border-color: @line-gray;
  border-radius: 0;
}

.form-panel {
  margin-top: 20px;
  .panel-default {
    background-color: @light-gray;
    border: none;
    border-radius: 0;
    .panel-body {
      padding: 30px;
      .site-page-header {
        margin: 0;
        text-align: center;
        h2 {
          color: var(--market-main);
          font-weight: 700;
          margin: 0 0 30px;
        }
      }
    }
    .form-actions-container {
      color: var(--market-main);
      display: flex;
      justify-content: space-between;
      .checkbox {
        padding: 0;
      }
      a {
        color: var(--market-main);
      }
    }
    .site-form-btn {
      background-color: transparent;
      border: 0;
      color: @black;
      font-size: 18px;
      text-transform: uppercase;
      &:focus, &:hover {
        color: var(--market-main);
      }
      i {
        color: var(--market-main);
      }
    }
  }
  .form-group {
    .control-label {
      padding-right: 0;
      padding-top: 16px;
      text-align: left;
    }
    .form-control {
      border: none;
      height: 50px;
    }
  }
}

/* Form Validation Errors */
.form-global-errors {
  margin-bottom: 15px;
}

ul.form-field-error-messages {
  margin: 0;
  padding: 5px 10px;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: var(--market-main);
  }
}

ul.form-global-error-messages {
  padding-top: 10px;

  li {
    list-style-type: square;
    color: @text-gray;
  }
}

.password-instructions {
  border-radius: 0;
  color: #999;
  padding: 5px 8px;
  position: relative;
  top: 20px;
}

#subForm {
  .form-control {
    &:focus {
      border-color: @black;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .25);
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Page
//
// <editor-fold>
// =====================================================================================================================
.page {
  margin-top: 15px;
  @media (min-width: @screen-sm-min) {
    margin-top: 20px;
  }
  @media (min-width: @screen-md-min) {
    margin-top: 30px;
  }
  p {
    line-height: 1.15em;
    font-size: 15px;
  }
  h2, h3, h4, h5 {
    font-weight: normal;
    text-transform: uppercase;
  }
  h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  h3 {
    margin-top: 1em;
    margin-bottom: 0.6em;
    color: @black;
    border: none;
    text-transform: none;
    font-weight: 400;
  }
  h4 {
    color: #cc092f;
    font-size: 22px;
    margin: 1em 0px;
  }
  .panel-footer {
    border: 1px solid #ddd;
    line-height: 1.5;
  }
  .panel-body {
    border: 1px solid #ddd;
    border-bottom: none 0px;
  }
}

#subscribe {
  header h1 {
    display: none;
  }
}

.page-header-container {
  align-items: center;
  background-color: #258EC2;
  display: flex;
  height: 131px;
  @media (min-width: @screen-sm-min) {
    height: 147px;
  }
  @media (min-width: @screen-md-min) {
    height: 200px;
  }
  @media (min-width: @screen-lg-min) {
    height: 226px;
  }
  &:before {
    border-bottom: 131px solid var(--market-main);
    border-right: 131px solid transparent;
    left: 0;
    position: absolute;
    height: 0;
    width: 0;
    @media (min-width: @screen-sm-min) {
      border-bottom: 147px solid var(--market-main);
      border-right: 147px solid transparent;
    }
    @media (min-width: @screen-md-min) {
      border-bottom: 200px solid var(--market-main);
      border-right: 200px solid transparent;
    }
    @media (min-width: @screen-lg-min) {
      border-bottom: 226px solid var(--market-main);
      border-right: 226px solid transparent;
    }
  }
  .page__title-container {
    position: relative;
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
  }
}

.page__title {
  color: @white;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 32px;
  }
  @media (min-width: @screen-md-min) {
    font-size: 36px;
  }
}

.page__subtitle {
  color: @bswh-yellow;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    font-size: 22px;
  }
  @media (min-width: @screen-md-min) {
    font-size: 26px;
  }
}
// </editor-fold>


// =====================================================================================================================
// Date Range Picker
//
// <editor-fold>
// =====================================================================================================================
.daterangepicker {
  border: none!important;
  border-radius: 0!important;
  margin: 0!important;
  &:before {
    display: none!important;
  }

  .calendar {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      max-width: 230px!important;
    }
  }

  .ranges {
    li {
      border-radius: 0;
      color: @black;
      &:hover {
        background: var(--market-main);
        border: 1px solid var(--market-main);
        color: @white;
      }
    }
    li.active {
      background: var(--market-main);
      border: 1px solid var(--market-main);
    }
  }

  .range_inputs .btn-sm {
    border-radius: 0;
  }

  td {
    border-radius: 0!important;
  }

  td.active, td.active:hover {
    background-color: var(--market-main)!important;
  }

  .input-mini {
    border-radius: 0!important;
  }

  .input-mini.active {
    border: 1px solid @black!important;
  }

  .input-mini.active:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px!important;
  }

  td.off.start-date, td.off.end-date {
    color: @white!important;
    opacity: .5;
  }
}
// </editor-fold>


// =====================================================================================================================
// Headers
//
// <editor-fold>
// =====================================================================================================================

.content-title, .content-title a {
  transition: color ease-in-out .25s;
  @media (max-width: @screen-xs-max) {
    font-size: 16px;
  }
  &:hover, &:focus {
    color: @market-hover;
  }
}

.content.homepage {
  background-color: @white;
  border-bottom: 1px solid @line-gray;
  margin-top: 0;
  padding-bottom: 35px;
  .site-page-header {
    border-bottom: none;
    margin: 35px 0;
    @media (max-width: @screen-xs-max) {
      margin: 20px 0;
    }
    h2 {
      display: inline-block;
      margin: 0;
      @media (max-width: @screen-xs-max) {
        margin-top: 5px;
      }
      a {
        color: @black;
        transition: ease-in-out .25s;
        &:focus, &:hover {
          color: @market-hover;
        }
      }
    }
  }
}

.site-page-header {
  @media (min-width: @screen-sm-max) {
    margin: 20px 0;
  }
  padding-bottom: 0;
  h3 {
    margin-top: 0;
  }
  h3, h3>a {
    color: @black;
    text-transform: uppercase;
    transition: ease-in-out .25s;
    &.hoverable {
      &:focus, &:hover {
        color: @market-hover;
      }
    }
  }
  .view-more-btn {
    float: right;
  }
  a.searchbox-icon {
    background-color: @black;
    color: @white;
    float: right;
    font-size: 17px;
    margin-right: 10px;
    padding: 4px 6px;
    text-transform: uppercase;
    transition: ease-in .25s;
    &:focus, &:hover {
      background-color: var(--market-main);
    }
  }
}

.header {
  margin-bottom: 30px;
  h1 {
    font-weight: normal;
    text-transform: uppercase;
  }
}

.header-title {
  color: @black;
  text-transform: none;
}

.header-search-title {
  text-transform: none;
}

.search-header--search-results-for {
  text-transform: uppercase;
}

.search-header--query {
  text-transform: none;
  font-style: italic;
  font-weight: 400;
}

.search-header--channel {
  text-transform: none;
}

.header-search {
  h1 {
    @media (max-width: @screen-xs-max) {
      font-size: 28px;
      span {
        font-size: 26px;
      }
    }
  }
}

.no_results_found {
  font-size: 20px;
}

div.search-again {
  form.searchbox {
    div.input-group {
      margin-top: 30px;
      width: 100%;
      z-index: 999;
      input.searchbox-input {
        min-height: 35px;
        font-size: 18px;
        @media (max-width: @screen-xs-max) { font-size: 18px; }
        margin-top: 1px;
      }
      span#search-addon {
        padding: 0;
        background-color: @black;
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important;
        button {
          border-radius: 0!important;
          background-color: @black;
          color: white;
        }
      }
    }
  }
}
// </editor-fold>


// =====================================================================================================================
// Buttons
//
// <editor-fold>
// =====================================================================================================================
.fa-releases:before {
  content: "\f15b";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.fa-photos:before {
  content: "\f030";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.fa-videos:before {
  content: "\f144";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
// </editor-fold>


// =====================================================================================================================
// Share Modal
//
// <editor-fold>
// =====================================================================================================================
.social-media-container {
  display: inline;
  .social-media {
    a.btn, a.btn.dropdown-toggle {
      border: none;
      box-shadow: none;
      color: @line-gray;
      font-size: 22px;
      padding: 0 7px;
      transition: ease-in-out .25s;
      background: none;
      @media (min-width: @screen-sm-max) {
        font-size: 21px;
      }
    }

    #download-options {
      border-radius: 0;
      left: auto;
      right: 0;
      padding: 5px 15px;
      .download-options__header h4 {
        color: var(--market-main);
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding: 5px 0;
        text-transform: uppercase;
      }
      .download-options__title h4 {
        color: @black;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        padding: 5px 0;
        text-transform: capitalize;
      }
      .download-options__item a {
        background: transparent;
        color: @black;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        padding: 5px 0;
        &:hover {
          color: var(--market-main);
        }
        i {
          margin: 3px 0 0 5px;
        }
      }
      .download-options__item--with-divider {
        border-bottom: 1px solid @text-gray;
        padding-bottom: 5px;
        margin-bottom: 5px
      }
    }

    @media (max-width: @screen-xs-max) {
      a.btn.facebook {
        padding-left: 0;
      }
    }

    .facebook {
      &:hover, &:focus {
        color: #3b5998;
      }
    }

    .twitter {
      &:hover, &:focus {
        color: #55acee;
      }
    }

    .linkedin {
      &:hover, &:focus {
        color: #0077B5;
      }
    }

    .instagram {
      &:hover, &:focus {
        color: #125688;
      }
    }

    .pinterest {
      &:hover, &:focus {
        color: #cb2027;
      }
    }
  }
}

dd.error {
  color: red;
}
// </editor-fold>


// =====================================================================================================================
// Toast
//
// <editor-fold>
// =====================================================================================================================
#toast-container > div {
  opacity: 1!important;
  border: solid 1px #d0e9c6;
  padding: 15px 15px 15px 15px;
}

#toast-container > .toast-success {
  background-image: none!important;
}

.toast-success {
  background-color: #dff0d8!important;
  color: #3c763d!important;
}

.toast-close-button {
  color: #3c763d!important;
}
// </editor-fold>
